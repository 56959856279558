import React from 'react'
import Title from '../components/Title'

const Home = () => {
  return (
    <div>
      <Title headline="Home"/>
    </div>
  )
}

export default Home
