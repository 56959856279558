import React from 'react'
import './loader.scss'

const Loader = () => {
  return (

    /* https://www.w3schools.com/howto/howto_css_loader.asp */
    <div className='loader'>    </div>
  )
}

export default Loader